<template>
  <div class="form container">
    <div class="title w">{{ form.title }}</div>
    <div class="info w" v-if="form.show_nums == 1">
      截止到目前，共有
      {{ form.default_nums != "" ? form.default_nums : 0 }}人参与本活动。
    </div>
    <div class="content w" v-html="form.describe">{{ form.describe }}</div>
    <van-form @submit="onSubmit" class="w" label-width="2rem">
      <div v-for="(item, index) in form.items" :key="index">
        <van-cell-group>
          <van-field
            v-if="item.setting == 1"
            :label="item.title"
            :placeholder="item.tip"
            :name="item.name"
            v-model="submit[item.name]"
            :required="item.is_empty == 1"
            :disabled="item.status == 0"
            :maxlength="item.length > 0 ? item.length : null"
            :rules="rules.name"
          />
          <van-field
            v-if="item.setting == 2"
            :label="item.title"
            :placeholder="item.tip"
            :name="item.name"
            :required="item.is_empty == 1"
            v-model="submit[item.name]"
            :disabled="item.status == 0"
            :maxlength="item.length > 0 ? item.length : null"
            :rules="item.match == 1 ? rules.phone : ''"
          />
          <van-field
            v-if="item.setting == 9 && (item.type == 'text' || item.type == 'textarea')"
            :type="item.type"
            :label="item.title"
            :placeholder="item.tip"
            :required="item.is_empty == 1"
            :name="item.name"
            v-model="submit[item.name]"
            :disabled="item.status == 0"
            :maxlength="item.length > 0 ? item.length : null"
            :rules="item.match == 2 ? rules.email : item.match == 3 ? rules.number : item.match == 4 ? rules.date : null"
          />
          <van-field
            v-if="item.setting == 9 && item.type == 'select'"
            :label="item.title"
            :placeholder="item.tip"
            :name="item.name"
            :required="item.is_empty == 1"
            :is-link="true"
            readonly
            v-model="submit[item.name]"
            :disabled="item.status == 0"
            :maxlength="item.length > 0 ? item.length : null"
            @click="openPickerOption(item.options, item.name)"
          ></van-field>
          <van-field v-if="item.setting == 9 && item.type == 'radio'" :name="item.name" :label="item.title" :required="item.is_empty == 1">
            <template #input>
              <van-radio-group v-model="submit[item.name]" direction="horizontal">
                <van-radio v-for="(radio, raIndex) in item.options" :key="raIndex" :name="radio.id">{{ radio.title }}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-if="item.setting == 9 && item.type == 'checkbox'" :name="item.name" :label="item.title" :required="item.is_empty == 1">
            <template #input>
              <van-checkbox-group v-model="submit[item.name]" direction="horizontal">
                <van-checkbox v-for="(checkbox, raIndex) in item.options" :key="raIndex" :name="checkbox.id" shape="square">{{ checkbox.title }}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
          <van-field v-if="item.setting == 9 && item.type == 'file' && item.file_type == 1" :name="item.name" :label="item.title" :required="item.is_empty == 1">
            <template #input>
              <van-uploader
                :after-read="afterRead"
                accept="image/*"
                :name="item.name"
                :max-size="item.file_size * 1024 * 1024"
                :max-count="item.file_extension.length > 0 ? item.file_extension : 9999"
                v-model="submit[item.name]"
                :upload-text="item.tip"
              />
            </template>
          </van-field>
        </van-cell-group>
      </div>
      <div style="margin: 16px;">
        <van-button color="#ff7172" round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="pickOptions" @confirm="onConfirm" @cancel="showPicker = false" value-key="title" />
    </van-popup>
  </div>
</template>

<script>
  import Vue from "vue";
  import { getform, adddata, videoUp } from "@/utils/api";
  import { fetchClientFunction, getClientFunction, setWebFunction } from "@/utils/interface";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  import Vconsole from "vconsole";
  import { setStore, getStore, clearStore } from "@/utils/storage";

  export default {
    name: "biaodan",
    data() {
      return {
        deviceToken: "",
        deviceType: "",
        showPicker: false, //选择器
        pickOptions: [],
        pickName: "", //对应submit提交键名
        name: "",
        submit: {},
        phone: "",
        form: {},
        share_config: {
          title: "",
          share_img: "",
          share_link: "",
          summary: "",
        },
        rules: {
          name: [
            {
              trigger: "onBlur",
              validator(val) {
                return val.length > 0;
              },
              message: "请填写您的姓名",
            },
          ],
          phone: [
            {
              validator(val) {
                return /^1[0-9]{10}$/.test(val);
              },
              trigger: "onBlur",
              message: "请填写正确的手机号",
            },
          ],
          email: [
            {
              tigger: "onBlur",
              validator(val) {
                var reg = new RegExp("^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$");
                return reg.test(val);
              },
            },
          ],
          number: [
            {
              tigger: "onBlur",
              validator(val) {
                var reg = new RegExp("^[0-9]*$");
                return reg.test(val);
              },
            },
          ],
          date: [
            {
              tigger: "onBlur",
              validator(val) {
                var reg = new RegExp("^\\\\d{4}(\\\\-|\\\\/|.)\\\\d{1,2}\\\\1\\\\d{1,2}$");
                return reg.test(val);
              },
            },
          ],
        },
      };
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
        this.getFormDetail(this.id);
      } else {
        this.$router.push({ name: "index" });
      }
    },
    mounted() {
      new Vconsole();
      this.fetchToken();
    },
    methods: {
      async getFormDetail(id) {
        const res = await getform(id);
        this.form = res.data.data;
        var arr = this.form.items;
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].type == "checkbox" || arr[i].type == "file") {
            Vue.set(this.submit, arr[i].name, []);
          } else {
            Vue.set(this.submit, arr[i].name, "");
          }
        }
        this.share_config.title = res.data.data.share_title || "";
        this.share_config.share_img = res.data.data.share_pic || "";
        this.share_config.share_link = this.$route.path;
        this.share_config.summary = this.share_config.share_desc || "";
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link + this.query, this.share_config.summary);
      },
      afterRead(file, name) {
        //图片上传之后
        file.status = "uploading";
        file.message = "上传中...";
        let fd = new FormData();
        fd.append("file", file.file);
        fd.append("upload_source", 53);
        videoUp(fd)
          .then(res => {
            file.status = "done";
            file.message = "上传成功";
            let url = res.data.data.aliyun_url;
            // this.submit[name.name][name.index].file = url;
            this.submit[name.name][name.index].content = url;
            console.log(this.submit);
          })
          .catch(err => {
            file.status = "failed";
            file.message = "上传失败";
          });
      },
      onSubmit(object) {
        console.log("OBject" + object);
        let data = Object.assign({ form_id: this.form.id }, this.submit);
        adddata(data, this.deviceToken, this.deviceType)
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
            if (err.code == 422) {
              var html = ``;
              for (let i in err.date.errdata) {
                html = html + `<p style="font-size:16px;color:#ff4d4f">${err.date.errdata[i]}</p>`;
              }
              this.$dialog
                .alert({
                  message: html,
                })
                .then(() => {
                  // on close
                });
            } else {
              this.$dialog
                .alert({
                  message: err.data.errmsg,
                })
                .then(() => {
                  // on close
                });
            }
          });
      },

      fetchToken() {
        let data2 = {
          type: "headersData",
          params: "",
        };
        getClientFunction(data2, res => {
          console.log(res);
          setStore("accessToken", res.Authorization);
          console.log(getStore("accessToken"));
          this.deviceToken = res["device-token"];
          this.deviceType = res["device-type"];
        });
      },
      openPickerOption(option, name) {
        //打开选择的option
        this.pickOptions = option;
        this.pickName = name;
        this.showPicker = true;
      },
      onConfirm(value) {
        //选择器确认
        console.log(value);
        this.submit[this.pickName] = value.title;
        this.showPicker = false;
      },
    },
  };
</script>

<style scoped>
  .w {
    width: 92%;
    margin: 0 auto;
  }
  .form {
    min-height: 100vh;
  }
  .form .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0;
  }
  .form .info {
    text-align: center;
    font-size: 14px;
    color: #999;
  }
  .content {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .form >>> .van-radio--horizontal,
  .form >>> .van-checkbox--horizontal {
    margin-bottom: 10px;
  }
</style>
